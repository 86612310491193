import React, { useEffect, useRef, useState } from "react";
import {
  getZoomedLines,
  getZoomedSubstations,
} from "../../../http_services/api_request";
import MainLayout from "../../../layout/main_layout";
import {
  new_projects,
  new_projects_substations,
} from "../../../utils/data/new_projects";
import { createPopupContent } from "../../../utils/helper_functions/create_popup";
import { images } from "../../../utils/helper_functions/image_url_builder";
import {
  colorMap,
  createPolygon,
} from "../../../utils/helper_functions/util_functions";
import mapstyles from "../../global/global.module.scss";
import Legends from "../../ui_components/legends/map_legends";

const USA_MAP: React.FC = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<google.maps.Map<HTMLDivElement> | null>(null);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  const [data, setData] = useState<any[]>([]);
  const [bounds, setBounds] = useState<any>();

  useEffect(() => {
    if (window.google && window.google.maps && mapRef.current) {
      // initialize map only when maps is loaded.
      initMap();
      addProjectsToMap();
    }
    return () => {
      //destroy map instance
      if (mapInstanceRef.current) {
        google.maps.event.clearInstanceListeners(mapInstanceRef.current);
        mapInstanceRef.current = null;
      }
    };
  }, []);
  const initMap = () => {
    if (mapRef.current) {
      mapInstanceRef.current = new google.maps.Map(mapRef.current, {
        center: {
          lat: 38.35007007919518,
          lng: -78.52203586582031,
        },
        zoom: 9,
      });
      infoWindowRef.current = new google.maps.InfoWindow();

      const initialBounds = mapInstanceRef.current.getBounds();

      if (initialBounds) {
        setBounds(initialBounds);
      }
      mapInstanceRef.current.addListener("zoom_changed", () => {
        const newBounds = mapInstanceRef.current!.getBounds();
        setBounds(newBounds);
      });

      mapInstanceRef.current.addListener("dragend", async () => {
        const newBounds = mapInstanceRef.current!.getBounds();

        mapInstanceRef.current!.setCenter(mapInstanceRef.current!.getCenter());
        setBounds(newBounds);
        // fetch api
        const { data, substationsData } = await getData({ bounds: newBounds! });
        addDataToMap({ data, substationsData });
      });

      // call api when initialized map..
      mapInstanceRef.current.addListener("tilesloaded", async () => {
        const newBounds = mapInstanceRef.current!.getBounds();
        const { data, substationsData } = await getData({ bounds: newBounds! });
        addDataToMap({ data, substationsData });
      });

      const geoJsonData = data?.map((item: any) => ({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: item.geometry,
        },
        properties: {
          color: colorMap(Number(item.max_voltage)),
          strokeWidth: 2,
          opacity: 1,
          data: item,
        },
      }));

      new_projects_substations.forEach((project) => {
        const marker = new google.maps.Marker({
          position: { lat: project.lat, lng: project.long },
          map: mapInstanceRef.current!,
          title: project.Substation_name,
          icon: {
            url: images.blue_marker,
            scale: 10,
            fillColor: "purple",
            fillOpacity: 0.6,
            strokeWeight: 2,
            strokeColor: "black",
            scaledSize: new google.maps.Size(30, 30),
          },
        });
        const infoWindow = new google.maps.InfoWindow({
          content: `<div> <strong>${project.Substation_name}</strong></div>`,
        });
        marker.addListener("mouseover", () => {
          infoWindow.open(mapInstanceRef.current!, marker);
        });
        marker.addListener("mouseout", () => {
          infoWindow.close();
        });
      });

      const calculateAllPolygons = new_projects_substations?.map((item) => {
        const polygons = createPolygon(
          { lat: item.lat, lng: item.long },
          30,
          4
        );
        return {
          type: "Feature",
          name: item.Substation_name,
          geometry: {
            type: "Polygon",
            coordinates: [polygons],
          },
          properties: {
            name: item.Substation_name,
            color: "gray",
            title: item.Substation_name,
          },
        };
      });

      mapInstanceRef?.current?.data.addGeoJson({
        type: "FeatureCollection",
        features: [
          ...geoJsonData,
          // ...polygonGeoJsonData,
          ...calculateAllPolygons,
        ],
      });

      mapInstanceRef?.current?.data.setStyle((feature) => {
        const geometryType = feature.getGeometry().getType();
        if (geometryType === "LineString") {
          return {
            strokeColor: feature.getProperty("color"),
            strokeWeight: feature.getProperty("strokeWidth"),
            strokeOpacity: feature.getProperty("opacity"),
          };
        } else if (geometryType === "Polygon") {
          return {
            fillColor: feature.getProperty("color"),
            fillOpacity: 0.6,
            strokeWeight: 1,
            strokeColor: "#000000",
          };
        } else {
          return {};
        }
      });
    }
  };

  //init map ends here.

  const addProjectsToMap = () => {
    new_projects.forEach((project) => {
      const marker = new google.maps.Marker({
        position: { lat: project.lat, lng: project.long },
        map: mapInstanceRef.current!,
        title: project.Enverus_project_name,
        icon: {
          url: images.green_marker,
          scaledSize: new google.maps.Size(30, 30),
        },
      });
      const infoWindow = new google.maps.InfoWindow({
        content: `<div> <strong>${project.queue_number}</strong></div>`,
      });
      marker.addListener("mouseover", () => {
        infoWindow.open(mapInstanceRef.current!, marker);
      });
      marker.addListener("mouseout", () => {
        infoWindow.close();
      });
    });
  };

  const addDataToMap = ({
    data,
    substationsData,
  }: {
    data: any;
    substationsData: any;
  }) => {
    const geoJsonData = data?.map((item: any) => ({
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: item.geometry,
      },
      properties: {
        color: colorMap(Number(item.max_voltage)),
        strokeWidth: 2,
        opacity: 1,
        lineData: item,
      },
    }));

    mapInstanceRef.current?.data.addListener("click", function (event: any) {
      if (event.feature.getGeometry().getType() === "LineString") {
        const data = event.feature.getProperty("lineData");
        const coordinates = event.feature
          .getGeometry()
          .getArray()
          ?.map((latlng: any) => ({
            lat: latlng.lat(),
            lng: latlng.lng(),
          }));

        const midpointIndex = Math.floor(coordinates?.length / 2);
        const midpoint = coordinates[midpointIndex];
        const contentString = createPopupContent(data, "transmission_line");
        if (infoWindowRef.current && midpoint?.lat && midpoint?.lng) {
          mapInstanceRef.current!.setCenter({
            lat: midpoint?.lat,
            lng: midpoint?.lng,
          });
          infoWindowRef.current!.setPosition(
            new google.maps.LatLng(midpoint.lat, midpoint.lng)
          );
          infoWindowRef.current!.setContent(contentString);
          infoWindowRef.current!.open(mapInstanceRef.current!);
          google.maps.event.addListenerOnce(
            infoWindowRef.current!,
            "domready",
            () => {
              const closePopupLink = document.getElementById("close-popup");
              if (closePopupLink) {
                closePopupLink.addEventListener("click", (event) => {
                  event.preventDefault();
                  infoWindowRef.current!.close();
                });
              }
            }
          );
        }
      }
    });

    const polygonGeoJsonData = substationsData?.map((item: any) => ({
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: item.geometry,
      },
      properties: {
        name: item.name,
        color: "gray",
        title: item.name,
      },
    }));

    const calculateAllPolygons = new_projects_substations?.map((item) => {
      const polygons = createPolygon({ lat: item.lat, lng: item.long }, 30, 4);
      return {
        type: "Feature",
        name: item.Substation_name,
        geometry: {
          type: "Polygon",
          coordinates: [polygons],
        },
        properties: {
          name: item.Substation_name,
          color: "gray",
          title: item.Substation_name,
        },
      };
    });

    //check
    if (geoJsonData && polygonGeoJsonData && calculateAllPolygons) {
      mapInstanceRef.current!.data?.addGeoJson({
        type: "FeatureCollection",
        features: [
          ...geoJsonData,
          ...polygonGeoJsonData,
          ...calculateAllPolygons,
        ],
      });
    }
  };

  const getData = async ({ bounds }: { bounds: google.maps.LatLngBounds }) => {
    let data = null;
    let substationsData = null;
    if (bounds) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      data = await getZoomedLines({
        north_east: { lat: ne.lat(), long: ne.lng() },
        south_west: { lat: sw.lat(), long: sw.lng() },
        zoom_level: 12,
      });
      substationsData = await getZoomedSubstations({
        north_east: { lat: ne.lat(), long: ne.lng() },
        south_west: { lat: sw.lat(), long: sw.lng() },
        zoom_level: 12,
      });
    }
    return { data, substationsData };
  };

  return (
    <>
      <MainLayout>
        <div className={mapstyles.main_container}>
          <div className={mapstyles.map_container}>
            <div ref={mapRef} className={mapstyles.map} />
            <Legends />
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default USA_MAP;
