import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BasicTabs from "../components/ui_components/tabs/tabs";
import { getNewSubstations } from "../http_services/api_request";
import { new_projects } from "../utils/data/new_projects";
import styles from "./main_layout.module.scss";

type MainLayoutProps = {
  children: any;
  filterGenerationProjects?: (filterGenerationProjects: string[]) => void;
};

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  filterGenerationProjects,
}) => {
  const location = useLocation();
  const [newSubstations, setNewSubstations] = useState([]);
  const [projectsStatus, setProjectsStatus] = useState<
    { name: string; checked: boolean }[]
  >([
    { name: "Facility Study", checked: false },
    { name: "Interconnection Agreement", checked: false },
    { name: "Pre-Construction", checked: false },
    { name: "Under Construction", checked: false },
    { name: "System Impact Study", checked: false },
  ]);

  const setup = async () => {
    const substations = await getNewSubstations();
    if (substations?.length) {
      setNewSubstations(substations);
    }
  };
  useEffect(() => {
    setup();
  }, []);
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const [openProjectFilterDrawer, setOpenProjectFilterDrawer] = useState(false);
  const toggleProjectFilterDrawer = (open: boolean) => {
    setOpenProjectFilterDrawer(open);
  };

  const DrawerList = (
    <Box sx={{ width: 250, padding: 1 }} role="presentation">
      <ListItemIcon
        onClick={toggleDrawer(false)}
        className={styles.drawer_list_item}>
        <CloseIcon />
      </ListItemIcon>

      <BasicTabs
        label1={"Projects"}
        label2={"Substations"}
        children1={
          <List>
            {new_projects.map((item) => (
              <ListItem key={item.queue_number} disablePadding>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={false} />}
                    label={item.Enverus_project_name}
                  />
                </FormGroup>
              </ListItem>
            ))}
          </List>
        }
        children2={
          <List>
            {newSubstations.map((item: any) => (
              <ListItem key={item.project_name} disablePadding>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={false} />}
                    label={item.project_name}
                  />
                </FormGroup>
              </ListItem>
            ))}
          </List>
        }
      />
      <Divider />
      <br />
      <div className={styles.button_container}>
        <Button color="primary" variant="contained" disabled>
          Simulate
        </Button>
      </div>
    </Box>
  );

  const finalizeGenerationProjectsStatus = (index: number) => {
    const projectsStatusClone = [...projectsStatus];
    projectsStatusClone[index].checked = !projectsStatusClone[index].checked;
    setProjectsStatus(projectsStatusClone);
  };

  const projectsFilterDrawer = (
    <Box sx={{ width: 300, padding: 0, margin: 1 }} role="presentation">
      <ListItemIcon
        onClick={() => toggleProjectFilterDrawer(false)}
        className={styles.drawer_list_item}>
        <CloseIcon />
      </ListItemIcon>
      <List>
        <Typography variant="h6" align="left" style={{ padding: "0px 16px" }}>
          Project Status
        </Typography>
        <br />
        <Divider />
        {projectsStatus.map((item, index) => (
          <ListItem key={item.name} disablePadding sx={{ pl: 2 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.checked}
                    value={item}
                    onChange={(e) => finalizeGenerationProjectsStatus(index)}
                  />
                }
                label={item.name}
              />
            </FormGroup>
          </ListItem>
        ))}
      </List>
      <Divider />
      <div className={styles.button_container}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            const data = projectsStatus.reduce(
              (acc: string[], project: { name: string; checked: boolean }) => {
                if (project.checked) {
                  acc.push(project.name);
                }
                return acc;
              },
              []
            );
            filterGenerationProjects?.(data);
          }}>
          Filter
        </Button>
      </div>
    </Box>
  );

  return (
    <div>
      <div className={styles.navbar_container}>
        <MenuIcon onClick={toggleDrawer(true)} className={styles.menu_icon} />
        <Stack direction="row" spacing={2} sx={{ mr: 2 }}>
          {location.pathname === "/" && (
            <Button
              variant="text"
              onClick={() => toggleProjectFilterDrawer(true)}>
              <FilterListIcon /> Filter
            </Button>
          )}
          <Button variant="outlined" href="/">
            Projects
          </Button>
          <Button variant="outlined" href="/heat-map">
            Heat MAP
          </Button>
          <Button variant="outlined" href="/usa-map">
            USA MAP
          </Button>
        </Stack>
      </div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <Drawer
        open={openProjectFilterDrawer}
        anchor="right"
        onClose={() => toggleProjectFilterDrawer(false)}>
        {projectsFilterDrawer}
      </Drawer>
      {children}
    </div>
  );
};

export default MainLayout;
