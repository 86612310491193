import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from "@mui/icons-material/Key";
import { Box } from "@mui/material";
import { useState } from "react";
import { images } from "../../../utils/helper_functions/image_url_builder";
import styles from "./map_legends.module.scss";
const Legends = () => {
  const [isLegendOpen, setIsLegendOpen] = useState<boolean>(false);
  const handleKeyClick = () => {
    setIsLegendOpen(!isLegendOpen);
  };
  return (
    <>
      <div className={styles.box_container}>
        {!isLegendOpen ? (
          <div title="Legends" className={styles.closeIconDiv}>
            <KeyIcon onClick={handleKeyClick} />
          </div>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              backgroundColor: "white",
              padding: "10px 16px",
              justifyContent: "space-between",
              boxShadow: 3,
              alignItems: "flex-start",
              marginTop: "20px",
              height: "100%",
              width: "136px",
              borderRadius: "10px",
            }}>
            <CloseIcon
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={handleKeyClick}
            />

            <div className={styles.box}>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#b59f12",
                }}>
                <span className={styles.color_label}>115kV</span>
              </div>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#c73031",
                }}>
                <span className={styles.color_label}>230kV</span>
              </div>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#b54eb2",
                }}>
                <span className={styles.color_label}>500kV</span>
              </div>
            </div>
            <div className={styles.image_legend_div}>
              <div className={styles.blue_marker_div}>
                <img src={images.blue_marker} width={26} height={26} alt="" />

                <span>Power Substations</span>
              </div>

              <div className={styles.red_marker_div}>
                <img src={images.green_marker} width={26} height={26} alt="" />
                <span>TC1 Projects</span>
              </div>

              <div className={styles.red_marker_div}>
                <img src={images.red_marker} width={26} height={26} alt="" />
                <span>VA Dominion Projects</span>
              </div>
              <div className={styles.red_marker_div}>
                <img src={images.yellow_marker} width={26} height={26} alt="" />
                <span>New Projects</span>
              </div>
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

export default Legends;
