import {
  HEATMAP_PROJECT,
  SUBSTATION,
  TC1_PROJECT,
  ZOOMED_LINE,
} from "../types/types";
import { formatter } from "./util_functions";

type popupTypeEnum =
  | "generation_project"
  | "transmission_line"
  | "generation_project_hover"
  | "heatmap_project"
  | "heatmap_project_hover"
  | "substation_hover";

type projectTypeEnum =
  | TC1_PROJECT
  | ZOOMED_LINE
  | HEATMAP_PROJECT
  | SUBSTATION
  | {};
export const createPopupContent = (
  project: projectTypeEnum,
  popupType: popupTypeEnum
) => {
  const isGenerationProject = (proj: projectTypeEnum): proj is TC1_PROJECT =>
    popupType === "generation_project" ||
    popupType === "generation_project_hover";

  const isTransmissionLine = (proj: projectTypeEnum): proj is ZOOMED_LINE =>
    popupType === "transmission_line";

  const isSubstation = (proj: projectTypeEnum): proj is SUBSTATION =>
    popupType === "substation_hover";

  const isHeatMapProject = (proj: projectTypeEnum): proj is HEATMAP_PROJECT =>
    popupType === "heatmap_project" || popupType === "heatmap_project_hover";

  let contentString = "";
  if (isGenerationProject(project)) {
    if (popupType === "generation_project_hover") {
      contentString = `<div><p><strong>${project.project_id ?? "-"}</strong></p>
      <p><strong>${
        project.project_name ?? project.project_name_poi
      }</strong></p></div>`;
    } else {
      contentString = `<div>
      <h2 style="color: black; cursor: pointer;">${
        project.project_id ?? "-"
      }</h2>
      <p style="font-weight:bold;">${project.project_name_poi ?? "-"}</p>
      <p style="font-weight:bold;">${project.project_name ?? "-"}</p>
      <p style="font-weight:bold;">${formatter.format(
        project.mw_capacity ?? 0
      )} MW Capacity / ${formatter.format(project.mw_energy ?? 0)} MW Energy</p>
      <p>${project.transmission_owner ?? "-"}</p>
      <p style="max-width:400px; text-align:justify;">${project.poi ?? "-"}</p>
      <p>TOIF: <strong> $${formatter.format(
        project.transmission_owner_interconnection_facilities ?? 0
      )}</strong></p>
      <p>Physical Interconnection Network Upgrades: <span style="text-align:right; font-weight:bold"> $${formatter.format(
        project.physical_interconnection_network_upgrades ?? 0
      )}</span></p>
      <p>System Reliability Upgrades: <strong> $${formatter.format(
        project.system_reliability_network_upgrades ?? 0
      )}</strong></p>
      <p>Total Cost: <strong>$${formatter.format(
        project.total_cost ?? 0
      )}</strong></p>
      <p>10% of Allocation Cost: <strong>$${formatter.format(
        project.cost_allocation_10 ?? 0
      )}</strong></p>
      <p>Readiness Deposit 1: <strong>$${formatter.format(
        project.readiness_deposit_1 ?? 0
      )}</strong></p>
      <p>Readiness Deposit 2: <strong>$${formatter.format(
        project.readiness_deposit_2 ?? 0
      )}</strong></p>
      <div style="display:flex; align-items:center; justify-content:space-between; min-width:400px; height:16px;">
      ${
        project.project_id
          ? `<p>
      <a 
      href="#"
      id="summer-peak-analysis"
      style="color: blue; cursor: pointer; text-decoration:none; text-align:right;"
      >
      Summer Peak Analysis
      </a>
      </p>`
          : ``
      }
      ${
        project.project_id
          ? `<p>
      <a 
      href="#"
      id="summer-potential-congestion"
      style="color: blue; cursor: pointer; text-decoration:none; text-align:right;"
      >
      Summer Potential Congestion
      </a>
      </p>`
          : ``
      }
      </div>

      <div style="display:flex; align-items:center; justify-content:space-between; min-width:400px;">
      ${
        project.project_id
          ? ` <p>
            <a
              href="#"
              id="view-more"
              style="color: blue; cursor: pointer; text-decoration:none; text-align:right;">
              System Reinforcements
            </a>
          </p>`
          : ``
      }
      ${
        project.project_id
          ? `<p>
            <a
              href="#"
              id="view-system-dependency"
              style="color: blue; cursor: pointer; text-decoration:none; text-align:right;">
              New Service Request Dependencies
            </a>
          </p>
          </div>`
          : ``
      }
    </div>`;
    }
  } else if (isTransmissionLine(project)) {
    contentString = `
    <div>
      <h3 style="color: black; cursor: pointer;">${project.name ?? ""}</h3>
     <p>Max Voltage ${
       project.max_voltage ? project.max_voltage / 1000 : 0
     }kV</p>
     <p> ${project.operator ?? ""}</p>

    </div>
  `;
  } else if (isHeatMapProject(project)) {
    if (popupType === "heatmap_project_hover") {
      //
      contentString = `<div> <strong>DFAX: ${project.factor}</strong></div>`;
    } else {
      contentString = `
    <div>
      <h2 style="color: black; cursor: pointer;">${
        project.queue_number ?? "-"
      }</h2>
      <p style="font-weight:bold;">Bus Number: ${project.bus_number ?? "-"}</p>
      <p style="font-weight:bold;">Pmax: ${formatter.format(
        project.pmax ?? 0
      )}</p>
      <p style="font-weight:bold;">DFAX: ${project.factor ?? 0}</p>
      <p style="font-weight:bold;">Offline Dispatch: ${formatter.format(
        project.offline_dispatch ?? 0
      )}</p>
      <p style="font-weight:bold;">MW Contribution: ${
        formatter.format(project.mw_contribution) ?? 0
      }</p>
         </div>
  `;
    }
  } else if (isSubstation(project)) {
    contentString = ` <div>
            <h2 style="color: black;">${"Bremo Substation"}</h2>
            <p style="color: black;">
              ${"Virginia Electric and Power Company"}
            </p>
            <p style="color: black;">
              Max Voltage: <strong>${"230,000"}MW </strong>
            </p>
          </div>`;
  } else {
    contentString = "";
  }
  return contentString;
};

export const createCustomGenerationProjectPopup = (markerId: any) => {
  //
  const contentString = `
  <div style="display: flex; flex-direction:column; gap:8px;">
  <div style="display:flex; justify-content:flex-end;">
    <a style="cursor:pointer; text-decoration:none;color:black; font-size:18px;" href="#" id="close-popup-icon"> X </a> </div>
    <input type="text" id="project_name_${markerId}" placeholder="Project Name..." />
    <input type="number" id="project_mwe_${markerId}" placeholder="MWE..." />
    <input type="number" id="project_mwc_${markerId}" placeholder="MWC..." />
    <input type="button"  style="cursor:pointer; background-color:#1B76D2; border-radius:2px; color:white; border:none; padding:4px; box-shadow:2px;" id="add_marker_button_${markerId}" value="ADD"/>
  </div>
  `;
  return contentString;
};
export const customGenerationProjectHoverPopup = (project: {
  display_name: string;
  project_name: string;
  mwe: number;
  mwc: number;
}) => {
  let content = `<div><strong>${project.display_name}</strong> <br/> <strong>MWE: ${project.mwe}MW</strong> <br/> <strong>MWC: ${project.mwc}MW </strong></div>`;
  return content;
};
