import { CircularProgress } from "@mui/material";

export const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10px",
      }}>
      <CircularProgress size={30} />
    </div>
  );
};
