import axios from "axios";
import {
  CUSTOM_GENERATION_REQUEST_BODY,
  ZOOMED_LINE_REQUEST_BODY,
  ZOOMED_SUBSTATION_REQUEST_BODY,
} from "../utils/types/types";
import {
  CREATE_CUSTOM_GENERATION_PROJECT,
  CUSTOM_GENERATION_PROJECTS,
  DELETE_CUSTOM_PROJECT,
  GENERATION_DEPENDENCY_PROJECTS,
  GENERATION_PROJECTS,
  HEATMAP_GENERATION_PROJECTS,
  NEW_SUBSTATIONS,
  SPECIFIC_LINES,
  SPECIFIC_SUBSTATIONS,
  SUBSTATIONS,
  SUBSTATIONS_CENTROIDS,
  SUMMER_PEAK_ANALYSIS,
  SUMMER_POTENTIAL_CONGESTION,
  TC1_PROJECTS,
  TRANSMISSION_PROJECTS,
  ZOOMED_LINES,
  ZOOMED_SUBSTATIONS,
} from "./http";

export const API_SERVER =
  process.env.REACT_APP_API_SERVER || "http://127.0.0.1:5001";

export const getZoomedLines = async (body: ZOOMED_LINE_REQUEST_BODY) => {
  try {
    const response = await axios.post(API_SERVER + ZOOMED_LINES, body);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const createCustomGenerationProject = async (
  body: CUSTOM_GENERATION_REQUEST_BODY
) => {
  try {
    const response = await axios.post(
      API_SERVER + CREATE_CUSTOM_GENERATION_PROJECT,
      body
    );
    return response.data;
  } catch (error) {
    return;
  }
};

export const getZoomedSubstations = async (
  body: ZOOMED_SUBSTATION_REQUEST_BODY
) => {
  try {
    const response = await axios.post(API_SERVER + ZOOMED_SUBSTATIONS, body);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
export const getSubstations = async () => {
  try {
    const response = await axios.get(API_SERVER + SUBSTATIONS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getSpecificLines = async () => {
  try {
    const response = await axios.get(API_SERVER + SPECIFIC_LINES);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
export const getSpecificSubstations = async () => {
  try {
    const response = await axios.get(API_SERVER + SPECIFIC_SUBSTATIONS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getNewSubstations = async () => {
  try {
    const response = await axios.get(API_SERVER + NEW_SUBSTATIONS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getGenerationProjects = async () => {
  try {
    const response = await axios.get(API_SERVER + GENERATION_PROJECTS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getCustomGenerationProjects = async () => {
  try {
    const response = await axios.get(API_SERVER + CUSTOM_GENERATION_PROJECTS);
    return response.data;
  } catch (error) {
    return;
  }
};

export const getGenerationProjectsHeatMap = async () => {
  try {
    const response = await axios.get(API_SERVER + HEATMAP_GENERATION_PROJECTS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getSubstationsCentroids = async () => {
  try {
    const response = await axios.get(API_SERVER + SUBSTATIONS_CENTROIDS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getTransmissionProjects = async (body: { id: string }) => {
  try {
    const response = await axios.post(API_SERVER + TRANSMISSION_PROJECTS, body);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getGenerationDependencyProjects = async (body: { id: string }) => {
  try {
    const response = await axios.post(
      API_SERVER + GENERATION_DEPENDENCY_PROJECTS,
      body
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const deleteCustomProject = async (id: number) => {
  try {
    const response = await axios.delete(
      API_SERVER + DELETE_CUSTOM_PROJECT + id
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getTC1Project = async () => {
  try {
    const response = await axios.get(API_SERVER + TC1_PROJECTS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getSummerPeakAnalysis = async (body: { id: string }) => {
  try {
    const response = await axios.post(API_SERVER + SUMMER_PEAK_ANALYSIS, body);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
export const getSummerPotentialCongestion = async (body: { id: string }) => {
  try {
    const response = await axios.post(
      API_SERVER + SUMMER_POTENTIAL_CONGESTION,
      body
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
