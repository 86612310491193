import Paper from "@mui/material/Paper";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./sorted_table.css";

const paginationModel = { page: 0, pageSize: 10 };

interface TableProps<T> {
  rows: T[];
  columns: GridColDef[];
  mapZoomData?: (lat: number, lng: number, geometry?: any) => void;
}

export const SortedTable = <T,>({
  rows,
  columns,
  mapZoomData,
}: TableProps<T>) => {
  return (
    <Paper sx={{ height: "88vh", width: "100%", marginTop: "8px" }}>
      <DataGrid
        rows={rows}
        columns={columns.map((col) => ({
          ...col,
          headerClassName: "wrap-header",
          cellClassName: "wrap-text",
        }))}
        onRowClick={(e: any) => {
          if (mapZoomData) {
            if (e.row?.rtep_id === "(Pending) / TC1-PH1-DOM-069") {
              mapZoomData(38.36462, -77.74181, e.row?.geometry);
            }
          }
        }}
        getRowHeight={() => "auto"}
        getRowClassName={(params) => `row-styles`}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
      />
    </Paper>
  );
};
