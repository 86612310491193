import blackMarker from "../../img/black_marker.png";
import blueMarker from "../../img/blue_marker.png";
import cyanMarker from "../../img/cyan_marker.png";
import greenMarker from "../../img/green_marker.png";
import purpleMarker from "../../img/purple_marker.png";
import redMarker from "../../img/red_marker.png";
import yellowMarker from "../../img/yellow_marker.png";

export const images = {
  green_marker: greenMarker,
  black_marker: blackMarker,
  red_marker: redMarker,
  yellow_marker: yellowMarker,
  purple_marker: purpleMarker,
  blue_marker: blueMarker,
  cyan_marker: cyanMarker,
};
