import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from "@mui/icons-material/Key";
import { Box } from "@mui/material";
import { useState } from "react";
import styles from "./map_legends.module.scss";

const HeatMapLegend = () => {
  const [isLegendOpen, setIsLegendOpen] = useState<boolean>(false);
  const handleKeyClick = () => {
    setIsLegendOpen(!isLegendOpen);
  };
  return (
    <>
      <div className={styles["box_container__heatmap"]}>
        {!isLegendOpen ? (
          <div title="Legends" className={styles.closeIconDiv}>
            <KeyIcon onClick={handleKeyClick} />
          </div>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              backgroundColor: "white",
              padding: "10px 16px",
              justifyContent: "space-between",
              boxShadow: 3,
              alignItems: "flex-start",
              marginTop: "20px",
              height: "100%",
              width: "110px",
              borderRadius: "10px",
            }}>
            <CloseIcon
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={handleKeyClick}
            />

            <div className={styles.box}>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#007700",
                }}>
                <span className={styles.color_label}>{"<= -0.2"}</span>
              </div>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#00cc00",
                }}>
                <span className={styles.color_label}>{"<= -0.1"}</span>
              </div>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#66ff66",
                }}>
                <span className={styles.color_label}>{"<= 0.0"}</span>
              </div>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#ffff00",
                }}>
                <span className={styles.color_label}>{"<= 0.1"}</span>
              </div>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#ffcc00",
                }}>
                <span className={styles.color_label}>{"<= 0.2"}</span>
              </div>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#ff9900",
                }}>
                <span className={styles.color_label}>{"<= 0.3"}</span>
              </div>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#ff3300",
                }}>
                <span className={styles.color_label}>{"<= 0.4"}</span>
              </div>
              <div
                className={styles.legend}
                style={{
                  backgroundColor: "#990000",
                }}>
                <span className={styles.color_label}>{" > 0.4"}</span>
              </div>
            </div>
          </Box>
        )}
      </div>
    </>
  );
};

export default HeatMapLegend;
