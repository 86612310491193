import { GridColDef } from "@mui/x-data-grid";
import { formatter } from "./../helper_functions/util_functions";

export const TRANSMISSION_PROJECT_COLUMN_NAMES: GridColDef[] = [
  {
    field: "rtep_id",
    headerName: "RTEP ID",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "title",
    headerName: "Title",
    headerAlign: "center",
    width: 320,
    flex: 0,
    align: "left",
  },
  {
    field: "transmission_owner",
    headerName: "TO",
    headerAlign: "center",
    width: 80,
  },

  {
    field: "time_estimate",
    headerName: "Time Estimate",
    headerAlign: "center",
    width: 120,
    align: "center",
  },
  {
    field: "total_cost",
    headerName: "Total Cost",
    type: "number",
    headerAlign: "center",
    width: 120,
    valueFormatter: (value: number, row) =>
      `$${formatter.format(row.total_cost)}`,
  },
  {
    field: "mw_impact",
    headerName: "MW Impact",
    headerAlign: "center",
    width: 100,
    type: "number",
    valueFormatter: (value: number, row) => `${row.mw_impact} MW`,
  },
  {
    field: "percent_allocation",
    headerName: "Percent Allocation",
    headerAlign: "center",
    type: "number",
    width: 80,
    valueFormatter: (value: number, row) => `${row.percent_allocation} %`,
  },
  {
    field: "cost",
    headerName: "Allocated Cost",
    headerAlign: "center",
    type: "number",
    width: 120,
    valueFormatter: (value: number, row) => `$${formatter.format(row.cost)}`,
  },
];

export const GENERATION_DEPENDENCY_PROJECT_COLUMN_NAMES: GridColDef[] = [
  { field: "dep_id", headerName: "Project ID" },
  { field: "dep_name", headerName: "Project Name", width: 400 },
  { field: "dep_status", headerName: "Project Status", width: 280 },
];

export const PEAK_ANALYSIS_COLUMN_NAMES: GridColDef[] = [
  { field: "area", headerName: "Area" },
  {
    field: "facility_description",
    headerName: "Facility Description",
    width: 300,
  },
  { field: "contingency_name", headerName: "Contigency Name", width: 250 },
  { field: "contingency_type", headerName: "Contigency Type" },
  { field: "dc_ac", headerName: "DC | AC", width: 70 },

  {
    field: "final_cycle_loading",
    headerName: "Final Cycle Loading",
    width: 100,
    type: "number",
    valueFormatter: (value: number, row) =>
      `${formatter.format(row?.final_cycle_loading)} %`,
  },
  {
    field: "rating_mva",
    headerName: "Rating MVA",
    type: "number",
    valueFormatter: (value: number, row) =>
      `${formatter.format(row?.rating_mva)}`,
  },
  { field: "rating_type", headerName: "Rating Type", align: "center" },
  { field: "mva_to_mitigate", headerName: "MVA to Mitigate", type: "number" },
  { field: "mw_contribution", headerName: "MW Contribution", type: "number" },
];
