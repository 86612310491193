export const ZOOMED_LINES = "/lines/zoomed";
export const ZOOMED_SUBSTATIONS = "/substations/zoomed";
export const SUBSTATIONS = "/substations";
export const SPECIFIC_LINES = "/lines/specific";
export const SPECIFIC_SUBSTATIONS = "/substations/specific";
export const NEW_SUBSTATIONS = "/substations/new";
export const GENERATION_PROJECTS = "/generation-projects";
export const CUSTOM_GENERATION_PROJECTS = "/generation-projects/custom";
export const CREATE_CUSTOM_GENERATION_PROJECT =
  "/generation-projects/custom/new";
export const DELETE_CUSTOM_PROJECT = "/generation-projects/custom/delete/";
export const TRANSMISSION_PROJECTS = "/transmission-projects";
export const SUBSTATIONS_CENTROIDS = "/substations/centroids";
export const HEATMAP_GENERATION_PROJECTS = "/generation-projects/factors";
export const TC1_PROJECTS = "/tc1-gen-projects";
export const GENERATION_DEPENDENCY_PROJECTS = "/generation-dependency-projects";
export const SUMMER_PEAK_ANALYSIS = "/summer_peak_analysis";
export const SUMMER_POTENTIAL_CONGESTION = "/summer_potential_congestion";
