import { MarkerClusterer } from "@googlemaps/markerclusterer";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import LayersIcon from "@mui/icons-material/Layers";
import {
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  createCustomGenerationProject,
  deleteCustomProject,
  getCustomGenerationProjects,
  getGenerationDependencyProjects,
  getGenerationProjects,
  getNewSubstations,
  getSpecificLines,
  getSpecificSubstations,
  getSummerPeakAnalysis,
  getSummerPotentialCongestion,
  getTC1Project,
  getTransmissionProjects,
} from "../../../http_services/api_request";
import MainLayout from "../../../layout/main_layout";
import {
  GENERATION_DEPENDENCY_PROJECT_COLUMN_NAMES,
  PEAK_ANALYSIS_COLUMN_NAMES,
  TRANSMISSION_PROJECT_COLUMN_NAMES,
} from "../../../utils/data/table_data";
import { tc1_project } from "../../../utils/data/tc1_project_data";
import {
  createCustomGenerationProjectPopup,
  createPopupContent,
  customGenerationProjectHoverPopup,
} from "../../../utils/helper_functions/create_popup";
import { images } from "../../../utils/helper_functions/image_url_builder";
import {
  colorMap,
  createPolygon,
  drawDottedLine,
} from "../../../utils/helper_functions/util_functions";
import { removeMarkers } from "../../../utils/map_functions/map_functions";
import {
  CUSTOM_GENERATION_PROJECT,
  CUSTOM_GENERATION_REQUEST_BODY,
  DRAWER_TYPE,
  GENERATION_DEPENDENCY_PROJECT,
  NEW_SUBSTATION,
  SPECIFIC_LINE,
  SPECIFIC_SUBSTATION,
  SUMMER_PEAK_ANALYSIS_TYPE,
  TC1_PROJECT,
  TRANSMISSION_PROJECT,
} from "../../../utils/types/types";
import mapstyles from "../../global/global.module.scss";
import Legends from "../../ui_components/legends/map_legends";
import { Loader } from "../../ui_components/loader/loader";
import { SortedTable } from "../../ui_components/table/sorted_table";
import BasicTabs from "../../ui_components/tabs/tabs";
import styles from "./project_map.module.scss";
const ProjectMap: React.FC = () => {
  // map ref and states
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<google.maps.Map | null>(null);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  const markerClusterRef = useRef<MarkerClusterer | null>(null);
  const [VAProjectMarkers, setVAProjectMarkers] = useState<
    google.maps.Marker[]
  >([]);
  const [TC1ProjectMarkers, setTC1ProjectMarkers] = useState<
    google.maps.Marker[]
  >([]);
  const [customProjectMarkers, setCustomProjectMarkers] = useState<
    google.maps.Marker[]
  >([]);
  const markerMapRef = useRef<Map<number, google.maps.Marker>>(new Map());
  const markerMapRefGen = useRef<Map<number, google.maps.Marker>>(new Map());
  const [projectMarkers, setProjectMarkers] = useState<google.maps.Marker[]>();
  let activeInfoWindow: google.maps.InfoWindow | null = null;
  const markerStates = new Map<
    number,
    {
      project_name: string;
      mwe: string;
      mwc: string;
    }
  >();

  //data states
  const [newSubstations, setNewSubstations] = useState<NEW_SUBSTATION[]>([]);
  const [generationProjects, setGenerationProjects] = useState<TC1_PROJECT[]>(
    []
  );
  const [tc1Projects, setTc1Projects] = useState<TC1_PROJECT[]>([]);
  const [customGenerationProjects, setCustomGenerationProjects] = useState([]);
  const [transmissionProjects, setTransmissionProjects] = useState<
    TRANSMISSION_PROJECT[]
  >([]);
  const [generationDependencyProjects, setGenerationDependencyProjects] =
    useState<GENERATION_DEPENDENCY_PROJECT[]>([]);
  const [summerPeakAnalysis, setSummerPeakAnalysis] = useState<
    SUMMER_PEAK_ANALYSIS_TYPE[]
  >([]);
  const [summerPotentialCongestion, setSummerPotentialCongestion] = useState<
    SUMMER_PEAK_ANALYSIS_TYPE[]
  >([]);

  //boolean states
  const [open, setOpen] = useState<boolean>(false);
  const [isLayerOpen, setIsLayerOpen] = useState<boolean>(false);
  const [isProjectListOpen, setIsProjectListOpen] = useState<boolean>(false);
  const [drawerType, setDrawerType] = useState<DRAWER_TYPE>("");
  const [showVAProjects, setShowVAProjects] = useState<boolean>(true);
  const [showTC1Projects, setShowTC1Projects] = useState<boolean>(true);
  const [showCustomProjects, setShowCustomProjects] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
  });

  useEffect(() => {
    if (window.google && mapRef.current && !mapInstanceRef.current) {
      mapInstanceRef.current = new google.maps.Map(mapRef.current, {
        center: { lat: 38.00616, lng: -77.93615 },
        zoom: 10,
      });
      infoWindowRef.current = new google.maps.InfoWindow();
    }
  }, []);
  useEffect(() => {
    if (mapInstanceRef.current) {
      fetchData();
    }
  }, [mapInstanceRef.current]);

  const mapZoomLineClick = (lat: number, lng: number, geometry?: any) => {
    //find the middle lat lng ratio if there are line strings
    if (lat && lng) {
      mapInstanceRef.current?.setCenter({ lat: lat, lng: lng });
      mapInstanceRef.current?.setZoom(14);
    }
  };

  const fetchTransmissionProjects = async (project_id: string) => {
    setIsLoading(true);
    setTransmissionProjects([]);
    try {
      setOpen(true);
      setDrawerType("transmission");
      toggleDrawer(true);
      const temp = await getTransmissionProjects({
        id: project_id,
      });
      setTransmissionProjects(temp);
      setIsLoading(false);
    } catch (error) {
      console.error("Unable to fetch System reinforcements");
      setIsLoading(false);
    }
  };

  const fetchSummerPeakAnalysis = async (project_id: string) => {
    setIsLoading(true);
    setSummerPeakAnalysis([]);
    try {
      setOpen(true);
      setDrawerType("summer_peak");
      toggleDrawer(true);
      const temp = await getSummerPeakAnalysis({ id: project_id });
      setSummerPeakAnalysis(temp);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Unable to fetch Sumemr Peak Analysis");
    }
  };

  const fetchSummerPotentialContigency = async (project_id: string) => {
    setIsLoading(true);
    setSummerPotentialCongestion([]);
    try {
      setOpen(true);
      setDrawerType("summer_congestion");
      toggleDrawer(true);
      const temp = await getSummerPotentialCongestion({ id: project_id });
      setSummerPotentialCongestion(temp);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Unable to fetch Sumemr Peak Analysis");
    }
  };

  const fetchGenerationDependencyProjects = async (project_id: string) => {
    setIsLoading(true);
    setGenerationDependencyProjects([]);
    try {
      setOpen(true);
      setDrawerType("dependency");
      toggleDrawer(true);
      const data = await getGenerationDependencyProjects({ id: project_id });
      setGenerationDependencyProjects(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(
        "Unable to fetch Generation new service request dependencies"
      );
    }
  };

  const deleteCustomProjects = async (id: number) => {
    try {
      const deletedProject = await deleteCustomProject(id);
      if (deletedProject) {
        handleAlert("Project Removed");
        const customGenerationProjects = await getCustomGenerationProjects();
        setCustomGenerationProjects(customGenerationProjects || []);
        const marker = markerMapRef.current.get(id);
        if (marker) {
          marker.setMap(null);
          markerMapRef.current.delete(id);
        }
      } else {
        handleAlert("Unable to remove the project");
      }
    } catch (error) {
      console.error("Unable to delete the custom project and fetch updated");
    }
  };

  const fetchData = async () => {
    try {
      const newSubstationsData = await getNewSubstations();
      const generationProjectsData = await getGenerationProjects();
      const customGenerationProjects = await getCustomGenerationProjects();
      const specificLinesData = await getSpecificLines();
      const specificSubstationsData = await getSpecificSubstations();
      const tc1ProjectData = await getTC1Project();
      setTc1Projects(tc1ProjectData || []);
      setNewSubstations(newSubstationsData || []);
      setGenerationProjects(generationProjectsData || []);
      setCustomGenerationProjects(customGenerationProjects || []);
      addLinesSubstationsToMap({
        specificLines: specificLinesData,
        specificSubstations: specificSubstationsData,
      });
      addGenProjectMarkers();
      addTC1ProjectMarkers();
      addSubstationMarkers();
      addCustomProjectsMarkers();
    } catch (error) {
      console.error("Error loading data:", error);
      return;
    }
  };

  const handleMarkerClick = async (
    marker: google.maps.Marker,
    project: TC1_PROJECT
  ) => {
    let contentString = createPopupContent(project, "generation_project");
    infoWindowRef.current!.setContent(contentString);
    infoWindowRef.current!.open(mapInstanceRef.current!, marker);
    google.maps.event.addListenerOnce(
      infoWindowRef.current!,
      "domready",
      () => {
        const viewMoreLink = document.getElementById("view-more");
        if (viewMoreLink) {
          viewMoreLink.addEventListener("click", async (event) => {
            event.preventDefault();
            if (project.project_id) {
              await fetchTransmissionProjects(project.project_id);
            }
          });
        }
      }
    );

    google.maps.event.addListenerOnce(
      infoWindowRef.current!,
      "domready",
      () => {
        const viewDependency = document.getElementById(
          "view-system-dependency"
        );
        if (viewDependency) {
          viewDependency.addEventListener("click", async (event) => {
            event.preventDefault();
            if (project.project_id) {
              await fetchGenerationDependencyProjects(project.project_id);
            }
          });
        }
      }
    );

    google.maps.event.addListenerOnce(
      infoWindowRef.current!,
      "domready",
      () => {
        const viewPeakAnalysis = document.getElementById(
          "summer-peak-analysis"
        );
        if (viewPeakAnalysis) {
          viewPeakAnalysis.addEventListener("click", async (event) => {
            event.preventDefault();
            if (project.project_id) {
              await fetchSummerPeakAnalysis(project.project_id);
            }
          });
        }
      }
    );
    google.maps.event.addListenerOnce(
      infoWindowRef.current!,
      "domready",
      () => {
        const viewPotentialCongestion = document.getElementById(
          "summer-potential-congestion"
        );
        if (viewPotentialCongestion) {
          viewPotentialCongestion.addEventListener("click", async (event) => {
            event.preventDefault();
            if (project.project_id) {
              await fetchSummerPotentialContigency(project.project_id);
            }
          });
        }
      }
    );
  };

  const toggleDrawer = (open: boolean) => () => {
    if (!open) {
      setDrawerType("");
    }
    setOpen(open);
  };

  const addGenProjectMarkers = () => {
    if (!mapInstanceRef.current || generationProjects.length === 0) return;
    const markers = generationProjects
      .map((project) => {
        const lat = parseFloat(project.lat);
        const lng = parseFloat(project.long);
        if (isNaN(lat) || isNaN(lng)) return null;
        if (tc1_project.includes(project.project_id)) return null;
        let marker = new google.maps.Marker({
          position: { lat, lng },
          map: mapInstanceRef.current!,
          title: project.project_id,
          icon:
            project.project_name !== "Arvonia Hodson"
              ? {
                  url: images.red_marker,
                  scaledSize: new google.maps.Size(28, 28),
                }
              : {
                  url: images.purple_marker,
                  scaledSize: new google.maps.Size(34, 28),
                },
        });
        (marker as any).projectDetails = {
          projectStatus: project.project_status,
        };
        const infoWindow = new google.maps.InfoWindow({
          content: createPopupContent(project, "generation_project_hover"),
        });
        marker.addListener("mouseover", () => {
          infoWindowRef.current!.close();
          infoWindow.open(mapInstanceRef.current!, marker);
        });
        marker.addListener("mouseout", () => infoWindow.close());
        marker.addListener("click", () => {
          infoWindow.close();
          handleMarkerClick(marker, project);
        });
        marker.addListener("dblclick", () => {
          infoWindowRef.current!.close();
        });
        mapInstanceRef.current?.addListener("click", () => {
          infoWindowRef.current!.close();
        });
        markerMapRefGen.current.set(project.id, marker);
        return marker;
      })
      .filter((marker): marker is google.maps.Marker => marker !== null);
    setVAProjectMarkers(markers);
    setProjectMarkers(markers);
  };

  const addCustomProjectsMarkers = () => {
    //add new custom project markers::
    const markers = customGenerationProjects
      .map((project: CUSTOM_GENERATION_PROJECT) => {
        const lat = project.lat;
        const lng = project.long;
        if (isNaN(lat) || isNaN(lng)) return null;
        let marker = new google.maps.Marker({
          position: { lat, lng },
          map: mapInstanceRef.current!,
          title: project.project_name,
          icon: {
            url: images.yellow_marker,
            scaledSize: new google.maps.Size(28, 28),
          },
        });
        let content = customGenerationProjectHoverPopup(project);
        const infoWindow = new google.maps.InfoWindow({
          content: content,
        });
        marker.addListener("mouseover", () => {
          infoWindowRef.current!.close();
          infoWindow.open(mapInstanceRef.current!, marker);
        });
        marker.addListener("mouseout", () => infoWindow.close());
        markerMapRef.current.set(project.id, marker);
        return marker;
      })
      .filter((marker): marker is google.maps.Marker => marker !== null);
    setCustomProjectMarkers(markers);
  };

  const addSubstationMarkers = () => {
    if (newSubstations?.length > 0) {
      newSubstations.forEach((substation: NEW_SUBSTATION) => {
        const lat = parseFloat(substation.lat);
        const lng = parseFloat(substation.long);
        if (isNaN(lat) || isNaN(lng)) {
          return;
        }
        const marker = new google.maps.Marker({
          position: { lat, lng },
          map: mapInstanceRef.current!,
          title: substation.project_name,

          icon: {
            url: images.blue_marker,
            scaledSize: new google.maps.Size(28, 28),
          },
        });
        const infoWindow = new google.maps.InfoWindow({
          content: `<div> <strong>${substation.project_name}</strong></div>`,
        });
        marker.addListener("mouseover", () => {
          infoWindow.open(mapInstanceRef.current!, marker);
        });
        marker.addListener("mouseout", () => {
          infoWindow.close();
        });
      });
    }
  };

  const addLinesSubstationsToMap = ({
    specificLines,
    specificSubstations,
  }: {
    specificLines: SPECIFIC_LINE[];
    specificSubstations: SPECIFIC_SUBSTATION[];
  }) => {
    if (!specificLines?.length && !specificSubstations?.length) return;

    const linesGeoJson = specificLines?.map((item: SPECIFIC_LINE) => ({
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: item.geometry?.map((coord: string[]) =>
          coord?.map(Number)
        ),
      },
      properties: {
        color: colorMap(Number(item.max_voltage)),
        strokeWidth: 6,
        opacity: 1,
        lineData: item,
      },
    }));

    const polygonSubstations = specificSubstations?.map(
      (item: SPECIFIC_SUBSTATION) => ({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            item.geometry.map((coord: string[]) => coord?.map(Number)),
          ],
        },
        properties: {
          name: item.name,
          color: "gray",
          title: item.name,
        },
      })
    );

    const calculatedSubstationPolygons = newSubstations
      ?.map((item: NEW_SUBSTATION) => {
        const lat = parseFloat(item.lat);
        const lng = parseFloat(item.long);
        if (isNaN(lat) || isNaN(lng)) {
          return null;
        }
        const polygons = createPolygon({ lat, lng }, 30, 4);

        return {
          type: "Feature",
          name: item.project_name,
          geometry: {
            type: "Polygon",
            coordinates: [polygons],
          },
          properties: {
            name: item.project_name,
            color: "gray",
            title: item.project_name,
          },
        };
      })
      .filter((item: any) => item !== null);

    mapInstanceRef.current!.data?.addGeoJson({
      type: "FeatureCollection",
      features: [
        ...linesGeoJson,
        ...polygonSubstations,
        ...calculatedSubstationPolygons,
      ],
    });

    mapInstanceRef.current!.data?.setStyle((feature: any) => {
      const geometryType = feature.getGeometry().getType();
      if (geometryType === "LineString") {
        return {
          strokeColor: feature.getProperty("color"),
          strokeWeight: feature.getProperty("strokeWidth"),
          strokeOpacity: feature.getProperty("opacity"),
        };
      } else if (geometryType === "Polygon") {
        return {
          fillColor: feature.getProperty("color"),
          fillOpacity: 0.6,
          strokeWeight: 2,
          strokeColor: "#000000",
        };
      } else {
        return {};
      }
    });
  };

  mapInstanceRef.current?.data.addListener("click", function (event: any) {
    if (event.feature.getGeometry().getType() === "LineString") {
      const data = event.feature.getProperty("lineData");
      const coordinates = event.feature
        .getGeometry()
        .getArray()
        ?.map((latlng: any) => ({
          lat: latlng.lat(),
          lng: latlng.lng(),
        }));
      if (data.name === "Gold Dale Sub Dominion to Morrisville Sub Dominion") {
        drawDottedLine(coordinates, mapInstanceRef.current!);
      }

      //create a function to find middle index..
      const midpointIndex = Math.floor(coordinates?.length / 2);
      const midpoint = coordinates[midpointIndex];
      const contentString = createPopupContent(data, "transmission_line");
      infoWindowRef.current!.setPosition(
        new google.maps.LatLng(midpoint.lat, midpoint.lng)
      );
      infoWindowRef.current!.setContent(contentString);
      infoWindowRef.current!.open(mapInstanceRef.current!);

      google.maps.event.addListenerOnce(
        infoWindowRef.current!,
        "domready",
        () => {
          const closePopupLink = document.getElementById("close-popup");
          if (closePopupLink) {
            closePopupLink.addEventListener("click", (event) => {
              event.preventDefault();
              infoWindowRef.current!.close();
            });
          }
        }
      );
    }
  });

  const filterGenerationProjects = (selectedProjectStatus: string[]) => {
    if (selectedProjectStatus.length === 0) {
      projectMarkers?.forEach((marker) => {
        if (!marker.getVisible()) marker.setVisible(true);
      });
      return;
    }
    projectMarkers?.map((marker) => {
      if (
        !selectedProjectStatus.includes(
          (marker as any).projectDetails.projectStatus
        )
      ) {
        marker.setVisible(false);
      } else if (
        selectedProjectStatus.includes(
          (marker as any).projectDetails.projectStatus
        )
      ) {
        marker.setVisible(true);
      }
    });
  };
  const handleAlert = (message: string) => {
    setShowAlert({
      show: true,
      message: message,
    });
    setTimeout(() => {
      setShowAlert({ show: false, message: "" });
    }, 1000);
  };

  const handleShowVAProjects = () => {
    if (mapInstanceRef.current!) {
      if (!showVAProjects) {
        addGenProjectMarkers();
      } else {
        removeMarkers(VAProjectMarkers);
      }
      setShowVAProjects(!showVAProjects);
    }
  };

  const addCustomProjectClickHandler = () => {
    setIsButtonDisabled(true);
    let tempCursor = true;
    var allowHover = false;
    var allowDeleteMarker = true;
    mapInstanceRef.current?.setOptions({ draggableCursor: "crosshair" });
    mapInstanceRef.current?.addListener("click", (event) => {
      infoWindowRef.current?.close();
      mapInstanceRef.current?.setOptions({ draggableCursor: "" });
      if (tempCursor) {
        const markerId = Date.now();
        const marker = new google.maps.Marker({
          position: event.latLng,
          map: mapInstanceRef.current!,
          icon: {
            url: images.yellow_marker,
            scaledSize: new google.maps.Size(34, 34),
          },
        });
        markerStates.set(markerId, {
          project_name: "",
          mwe: "",
          mwc: "",
        });
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const uniqueInfoWindow = new google.maps.InfoWindow({
          content: createCustomGenerationProjectPopup(markerId),
        });
        if (activeInfoWindow) {
          activeInfoWindow.close();
        }
        activeInfoWindow = uniqueInfoWindow;
        uniqueInfoWindow.open(mapInstanceRef.current!, marker);
        tempCursor = false;

        google.maps.event.addListenerOnce(uniqueInfoWindow, "domready", () => {
          const closePopupLink = document.getElementById("close-popup-icon");
          if (closePopupLink) {
            closePopupLink.addEventListener("click", (event) => {
              event.preventDefault();
              uniqueInfoWindow?.close();
              // setIsButtonDisabled(true);
              // marker.setMap(null);
            });
          }
        });

        google.maps.event.addListenerOnce(uniqueInfoWindow, "domready", () => {
          const projectNameInput = document.getElementById(
            `project_name_${markerId}`
          ) as HTMLInputElement;
          const projectMWEInput = document.getElementById(
            `project_mwe_${markerId}`
          ) as HTMLInputElement;
          const projectMWCInput = document.getElementById(
            `project_mwc_${markerId}`
          ) as HTMLInputElement;

          const markerState = markerStates.get(markerId);
          if (markerState) {
            projectNameInput.value = markerState.project_name || "";
            projectMWEInput.value = markerState.mwe || "";
            projectMWCInput.value = markerState.mwc || "";
          }
          projectNameInput.addEventListener("input", (e) => {
            markerStates.set(markerId, {
              ...(markerStates.get(markerId) || {
                project_name: "",
                mwe: "",
                mwc: "",
              }),
              project_name: (e.target as HTMLInputElement).value,
            });
          });
          projectMWEInput.addEventListener("input", (e) => {
            markerStates.set(markerId, {
              ...(markerStates.get(markerId) || {
                project_name: "",
                mwe: "",
                mwc: "",
              }),
              mwe: (e.target as HTMLInputElement).value,
            });
          });

          projectMWCInput.addEventListener("input", (e) => {
            markerStates.set(markerId, {
              ...(markerStates.get(markerId) || {
                project_name: "",
                mwe: "",
                mwc: "",
              }),
              mwc: (e.target as HTMLInputElement).value,
            });
          });

          const submitButton = document.getElementById(
            `add_marker_button_${markerId}`
          ) as HTMLButtonElement;
          if (submitButton) {
            submitButton.addEventListener("click", async (event) => {
              event.preventDefault();
              const project_name = projectNameInput.value;
              const mwe = parseFloat(projectMWEInput.value);
              const mwc = parseFloat(projectMWCInput.value);

              if (!project_name || !mwe || !mwc) {
                handleAlert("Please fill all the fields");
              } else if (project_name.length > 30) {
                handleAlert("Project name is too long");
                return;
              } else {
                setShowAlert({ show: false, message: "" });
                const body: CUSTOM_GENERATION_REQUEST_BODY = {
                  lat,
                  long: lng,
                  project_name,
                  mwe,
                  mwc,
                };
                try {
                  submitButton.disabled = true;
                  submitButton.style.background = "gray";
                  const response = await createCustomGenerationProject(body);
                  if (response) {
                    setIsButtonDisabled(false);
                    allowDeleteMarker = false;
                    handleAlert("Project Added");
                    markerMapRef.current.set(response.id, marker);
                    const newProjects = await getCustomGenerationProjects();
                    setCustomGenerationProjects(newProjects);

                    const content = customGenerationProjectHoverPopup(response);
                    allowHover = true;
                    uniqueInfoWindow.setContent(content);
                  } else {
                    handleAlert("Unable to add project");
                  }
                  submitButton.disabled = false;
                  submitButton.style.background = "#1B76D2";
                } catch (error) {
                  submitButton.disabled = false;
                  submitButton.style.background = "#1B76D2";
                  console.error("Unable to add custom project to the database");
                }
              }
            });
            setShowAlert({ show: false, message: "" });
          }
        });

        marker.addListener("click", () => {
          infoWindowRef.current?.close();
          if (activeInfoWindow) {
            activeInfoWindow.close();
          }
          // uniqueInfoWindow.open(mapInstanceRef.current!, marker);
          // activeInfoWindow = uniqueInfoWindow;
        });
        marker.addListener("mouseover", () => {
          infoWindowRef.current?.close();
          activeInfoWindow?.close();
          uniqueInfoWindow.open(mapInstanceRef.current!, marker);
          activeInfoWindow = uniqueInfoWindow;
        });
        marker.addListener("mouseout", () => {
          infoWindowRef.current?.close();
          if (allowHover) {
            uniqueInfoWindow.close();
          }
        });

        marker.addListener("dblclick", () => {
          if (allowDeleteMarker) {
            setIsButtonDisabled(false);
            uniqueInfoWindow.close();
            marker.setMap(null);
            markerStates.delete(markerId);
          }
        });
      } else {
        if (activeInfoWindow) {
          activeInfoWindow.close();
        }
        infoWindowRef.current?.close();
      }
    });
  };

  const addTC1ProjectMarkers = () => {
    if (!mapInstanceRef.current || tc1Projects.length === 0) return;
    const markers = tc1Projects
      .map((project) => {
        const lat = parseFloat(project.lat);
        const lng = parseFloat(project.long);
        if (isNaN(lat) || isNaN(lng)) return null;
        let marker = new google.maps.Marker({
          position: { lat, lng },
          map: mapInstanceRef.current!,
          title: project.project_id,
          icon: {
            url: images.green_marker,
            scaledSize: new google.maps.Size(28, 28),
          },
        });
        (marker as any).projectDetails = {
          projectStatus: project.project_status,
        };
        const infoWindow = new google.maps.InfoWindow({
          content: createPopupContent(project, "generation_project_hover"),
        });
        marker.addListener("mouseover", () => {
          infoWindowRef.current!.close();
          infoWindow.open(mapInstanceRef.current!, marker);
        });
        marker.addListener("mouseout", () => infoWindow.close());
        marker.addListener("click", () => {
          infoWindow.close();

          // uncomment this
          handleMarkerClick(marker, project);
        });
        marker.addListener("dblclick", () => {
          infoWindowRef.current!.close();
        });
        mapInstanceRef.current?.addListener("click", () => {
          infoWindowRef.current!.close();
        });
        markerMapRefGen.current.set(project.id, marker);
        return marker;
      })
      .filter((marker): marker is google.maps.Marker => marker !== null);

    setTC1ProjectMarkers(markers);
  };

  const handleShowTC1Projects = () => {
    if (mapInstanceRef.current!) {
      if (!showTC1Projects) {
        addTC1ProjectMarkers();
      } else {
        removeMarkers(TC1ProjectMarkers);
      }
      setShowTC1Projects(!showTC1Projects);
    }
  };
  const handleShowCustomProjects = () => {
    if (mapInstanceRef.current!) {
      if (!showCustomProjects) {
        addCustomProjectsMarkers();
      } else {
        removeMarkers(customProjectMarkers);
      }
      setShowCustomProjects(!showCustomProjects);
    }
  };

  const handleLayerClick = () => {
    setIsLayerOpen(!isLayerOpen);
  };

  const handleListClick = () => {
    setIsProjectListOpen(!isProjectListOpen);
  };

  const focusProjectMarker = (project: any, mapRef: any, popupType: string) => {
    const marker = mapRef.current.get(project.id);
    let contentString;
    if (popupType === "generation") {
      contentString = createPopupContent(project, "generation_project_hover");
    } else {
      contentString = customGenerationProjectHoverPopup(project);
    }
    infoWindowRef.current?.setContent(contentString);
    infoWindowRef.current!.open(mapInstanceRef.current!, marker);
  };
  return (
    <>
      <Snackbar
        open={showAlert.show}
        autoHideDuration={null}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={showAlert.message}
      />
      <MainLayout filterGenerationProjects={filterGenerationProjects}>
        <div className={mapstyles.main_container}>
          <div className={mapstyles.map_container}>
            <div ref={mapRef} className={mapstyles.map} />
            <div className={styles.layerListContainer}>
              {!isLayerOpen ? (
                <div className={styles.cursorPointer} title="Layers">
                  <LayersIcon onClick={handleLayerClick} />
                </div>
              ) : (
                <>
                  <div className={styles.layerList}>
                    <div className={styles.cursorPointer}>
                      <CloseIcon fontSize="small" onClick={handleLayerClick} />
                    </div>

                    <FormControlLabel
                      checked={showVAProjects}
                      control={<Checkbox name="va_dominion" size="small" />}
                      onChange={() => {
                        handleShowVAProjects();
                      }}
                      value={showVAProjects}
                      label={
                        <Typography
                          variant="body2"
                          className={styles.typography}>
                          VA Dominion
                        </Typography>
                      }
                      className={styles.checkbox}
                    />
                    <FormControlLabel
                      checked={showTC1Projects}
                      control={<Checkbox name="tc1_projects" size="small" />}
                      onChange={() => {
                        handleShowTC1Projects();
                      }}
                      value={showTC1Projects}
                      label={
                        <Typography
                          variant="body2"
                          className={styles.typography}>
                          TC1 Projects
                        </Typography>
                      }
                      className={styles.checkbox}
                    />

                    <FormControlLabel
                      checked={showCustomProjects}
                      control={<Checkbox name="custom_projects" size="small" />}
                      onChange={() => {
                        handleShowCustomProjects();
                      }}
                      value={showCustomProjects}
                      label={
                        <Typography
                          variant="body2"
                          className={styles.typography}>
                          Custom Projects
                        </Typography>
                      }
                      className={styles.checkbox}
                    />
                  </div>
                </>
              )}
            </div>

            {/* legends */}
            {!isLayerOpen && <Legends />}

            {/* project list */}
            {!isLayerOpen && (
              <div className={styles.listContainer}>
                <>
                  {!isProjectListOpen ? (
                    <div title="Projects list" className={styles.cursorPointer}>
                      <ExpandCircleDownIcon onClick={handleListClick} />
                    </div>
                  ) : (
                    <div className={styles.projectList}>
                      <div className={styles.projectListButtonStack}>
                        <CloseIcon fontSize="small" onClick={handleListClick} />
                        <Button
                          disabled={isButtonDisabled}
                          onClick={addCustomProjectClickHandler}>
                          Add Project
                        </Button>
                      </div>

                      <BasicTabs
                        label1={"Custom"}
                        label2={"TC1"}
                        children1={
                          customGenerationProjects?.length ? (
                            <>
                              {customGenerationProjects?.map(
                                (project: CUSTOM_GENERATION_PROJECT) => (
                                  <ListItemButton
                                    key={project.id}
                                    style={{
                                      margin: "auto",
                                      padding: "2px 0px 2px 10px",
                                      display: "flex",
                                      cursor: "default",
                                      gap: "20px",
                                    }}>
                                    <ListItemText
                                      style={{
                                        flex: 1,
                                        width: "4px",
                                        paddingRight: "3px",
                                        whiteSpace: "normal",
                                        wordBreak: "break-word",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        focusProjectMarker(
                                          project,
                                          markerMapRef,
                                          "custom"
                                        )
                                      }>
                                      <div>{project.display_name}</div>
                                    </ListItemText>
                                    <ListItemIcon
                                      style={{
                                        minWidth: "40px",
                                        cursor: "pointer",
                                      }}>
                                      <DeleteIcon
                                        fontSize="small"
                                        onClick={() =>
                                          deleteCustomProjects(project.id)
                                        }
                                      />
                                    </ListItemIcon>
                                  </ListItemButton>
                                )
                              )}
                            </>
                          ) : (
                            <Typography className={styles.typography__grayText}>
                              N/A
                            </Typography>
                          )
                        }
                        children2={
                          generationProjects?.length ? (
                            <>
                              {tc1Projects?.map(
                                (project: TC1_PROJECT) =>
                                  project.lat != null && (
                                    <ListItemButton
                                      key={project.id}
                                      style={{
                                        margin: "auto",
                                        padding: "2px 0px 2px 10px",
                                        display: "flex",
                                        width: "100%",
                                      }}
                                      onClick={() =>
                                        focusProjectMarker(
                                          project,
                                          markerMapRefGen,
                                          "generation"
                                        )
                                      }>
                                      <ListItemText
                                        style={{
                                          flex: 1,
                                          width: "4px",
                                          paddingRight: "3px",
                                          whiteSpace: "normal",
                                          wordBreak: "break-word",
                                        }}>
                                        <div>{project.project_id}</div>
                                      </ListItemText>
                                      <ListItemIcon
                                        style={{
                                          minWidth: "40px",
                                        }}></ListItemIcon>
                                    </ListItemButton>
                                  )
                              )}
                            </>
                          ) : (
                            <Typography className={styles.typography__grayText}>
                              N/A
                            </Typography>
                          )
                        }
                      />
                    </div>
                  )}
                </>
              </div>
            )}

            {/* drawer */}
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              {drawerType === "transmission" ? (
                <div className={styles.projectsDrawer}>
                  <Typography variant="h6">System Reinforcements</Typography>
                  {transmissionProjects && transmissionProjects?.length ? (
                    <SortedTable
                      columns={TRANSMISSION_PROJECT_COLUMN_NAMES}
                      rows={transmissionProjects}
                      mapZoomData={mapZoomLineClick}
                    />
                  ) : isLoading ? (
                    <Loader />
                  ) : (
                    <Typography variant="h6">No Projects Available</Typography>
                  )}
                </div>
              ) : drawerType === "dependency" ? (
                <div className={styles.projectsDrawer}>
                  <Typography variant="h6">
                    New Service Request Dependencies
                  </Typography>
                  {generationDependencyProjects &&
                  generationDependencyProjects?.length ? (
                    <SortedTable
                      rows={generationDependencyProjects}
                      columns={GENERATION_DEPENDENCY_PROJECT_COLUMN_NAMES}
                    />
                  ) : isLoading ? (
                    <Loader />
                  ) : (
                    <Typography variant="h6">No Projects Available</Typography>
                  )}
                </div>
              ) : drawerType === "summer_peak" ? (
                <div className={styles.projectsDrawer}>
                  <Typography variant="h6">Summer Peak Analysis</Typography>
                  {summerPeakAnalysis && summerPeakAnalysis?.length ? (
                    <SortedTable
                      rows={summerPeakAnalysis}
                      columns={PEAK_ANALYSIS_COLUMN_NAMES}
                    />
                  ) : isLoading ? (
                    <Loader />
                  ) : (
                    <Typography variant="h6">No Projects Available</Typography>
                  )}
                </div>
              ) : drawerType === "summer_congestion" ? (
                <div className={styles.projectsDrawer}>
                  <Typography variant="h6">
                    Summer Potential Congestion due to Local Energy
                    Deliverability
                  </Typography>
                  {summerPotentialCongestion &&
                  summerPotentialCongestion?.length ? (
                    <SortedTable
                      rows={summerPotentialCongestion}
                      columns={PEAK_ANALYSIS_COLUMN_NAMES}
                    />
                  ) : isLoading ? (
                    <Loader />
                  ) : (
                    <Typography variant="h6">No Projects Available</Typography>
                  )}
                </div>
              ) : (
                <div>Not Available</div>
              )}
            </Drawer>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default ProjectMap;
